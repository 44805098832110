
<div class="container bg-secondary mt-3 mb-3" id="#folio">

    <carousel>

        <slide>
            <img src="assets/img/dad-denise.JPG" alt="family" style="display: block; width: 100%;">
            <div class="carousel-caption d-none d-md-block text-white">
                <h3>Meet the Staff</h3>
                <p>Denise and Fred Paige</p>
            </div>
        </slide>

        <slide>
        <img src="assets/img/barn.JPG" alt="Morton Stlye Barn" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block text-white">
            <h3>Morton Stlye Barn</h3>
        </div>
        </slide>

        <slide>
        <img src="assets/img/horses-andrew.JPG" alt="Grazing Pasture" style="display: block; width: 100%;">
        <div class="carousel-caption d-none d-md-block">
            <h3>Grazing Pasture</h3>
        </div>
        </slide>

        <slide>
            <img src="assets/img/deagan-leaf-drop.JPG" alt="Third Generation of Horse enthusiasts" style="display: block; width: 100%;">
            <div class="carousel-caption d-none d-md-block">
            <h3>Third Generation of Horse Enthusiasts</h3>
            </div>
        </slide>

        <slide>
            <img src="assets/img/inside-barn.JPG" alt="second slide" style="display: block; width: 100%;">
            <div class="carousel-caption d-none d-md-block">
            <h3>Inside The Barn</h3>
            </div>
        </slide>

        <slide>
            <img src="assets/img/tack-room.JPG" alt="second slide" style="display: block; width: 100%;">
            <div class="carousel-caption d-none d-md-block">
            <h3>Tack Room</h3>
            </div>
        </slide>
        
    </carousel>

</div>