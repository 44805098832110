<!-- Navigation-->
<nav class="navbar navbar-expand-lg fixed-top py-3" id="mainNav">
    <div class="container">
        
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto mr-auto my-2 my-lg-0 ">
                <li class="nav-item"><a class="nav-link " href="#services">Services</a></li>
                <li class="nav-item "><a class="nav-link " href="#about">About</a></li>
                <li class="nav-item"><a class="nav-link " href="#contact">Contact</a></li>
            </ul>
        </div>

        <div>

            
        </div>
    </div>
</nav>