

<app-nav></app-nav>

<app-masthead></app-masthead>

<app-carousel></app-carousel>

<app-about></app-about>

<app-contact></app-contact>