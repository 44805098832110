<section class="page-section" id="contact">
    <div class="container mt-3 mb-3">
        <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
                <h2 class="text-white mt-0">Let's Get In Touch!</h2>
                <hr class="divider my-4" />
                <p class="text-white mb-5">Ready to board a horse with us? Send us an email with your contact information and the best time to call.</p>
                <a class="d-block" href="mailto:fpaigegreat60@gmail.com"><i class="fas fa-envelope fa-3x mb-3 text-white"></i></a>
            </div>
        </div>
    </div>