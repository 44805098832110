<!-- About-->
<section class="page-section bg-primary " id="about">
    <div class="container mt-3 mb-3">
        <div class="row justify-content-center">
            <div class="col-lg-8 text-center">
                <h2 class="text-white mt-0">We have what you need!</h2>
                <hr class="divider light my-4" />
                <p class="text-white-50 mb-4">We provide a luxurious barn, hay sourced from local farmers, and a wonderful atmosphere you and your horse will enjoy!</p>
                <a class="d-block text-white" href="mailto:fpaigegreat60@gmail.com">Lets Get Started</a>
            </div>
        </div>
    </div>
  </section>