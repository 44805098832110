<!-- Masthead-->
<header class="masthead">
    <div class="container h-100">
        <div class="row h-100 align-items-center justify-content-center text-center">
            <div class="col-lg-10 align-self-end">
                <h1 class="text-white font-weight-bold mb-5">Sunny Hills Farm</h1>
                <h2 class="text-white ">Taylorsville, Kentucky</h2>
                <hr class="divider my-5" />
            </div>
            <div class="col-lg-8 align-self-baseline">
                <p class="text-white font-weight-bold mb-5">Start your adventure with us. We are now accepting new boarders at this time. Contact for rates and options.</p>
                
                <a class="btn btn-primary btn-xl" href="#about">Find Out More</a>
                <br>
                <div class="mt-4 btn btn-xl text-white">
                    <h3>Email your contact information</h3>
                    <a class="d-block email" href="mailto:fpaigegreat60@gmail.com">
                        <i class="fas fa-envelope fa-3x mb-3 text-primary"></i>
                        <p class="text-white">fpaigegreat60@gmail.com</p>
                    </a>
                </div>
                
            </div>
            <app-services></app-services>
        </div>
    </div>
  </header>