<!-- Services-->
<section class="page-section" id="services">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-tree text-white mb-4"></i>
                    <h3 class="text-white h4 mb-2">The Farm</h3>
                    <p class="text-white-50 mb-0">40 beautiful acres of rolling hills in Taylorsville, KY.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-home text-white mb-4"></i>
                    <h3 class="text-white h4 mb-2">Facilities</h3>
                    <p class="text-white-50 mb-0">Morton Barn, tack room, riding corral and plenty of open pasture.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-horse text-white mb-4"></i>
                    <h3 class="text-white h4 mb-2">Ready for Guests</h3>
                    <p class="text-white-50 mb-0">Large stalls are ready to be filled!</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 text-center">
                <div class="mt-5">
                    <i class="fas fa-4x fa-plus-square text-white mb-4"></i>
                    <h3 class="text-white h4 mb-2">Family</h3>
                    <p class="text-white-50 mb-0">Boarder appreciation events on a regular basis.</p>
                </div>
            </div>
        </div>
    </div>
  </section>